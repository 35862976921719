.User-details h1 {
    font-family: Roboto;
    font-style: normal;
    font-size: 20px;
    line-height: 23px;

    color: #000000;
}

@media (max-width: 374px) {
    .User-details h1 {
        font-size: 16px;
    }
}

@media (max-width: 281px) {
    .User-details h1 {
        font-size: 14px;
    }
}

@media (min-width: 700px) {
    .user-top-level-details {
        display: grid;
        grid-template-columns: [profile-user-name]auto [spacer] 1fr [profile-user-score]auto [spacer] 16px [profile-accuracy]auto;
        align-items: center;
        grid-template-areas:
    "profile-user-name .  profile-user-score . profile-accuracy";
    }
}

@media (max-width: 700px) {
    .user-top-level-details {
        display: grid;
        grid-template-columns: [profile-user-name]auto [spacer] 1fr [profile-user-score]auto;
        grid-template-rows: auto auto;
        align-items: center;
        grid-template-areas:
    "profile-user-name .  profile-user-score"
    ". . profile-accuracy";
    }

    .User-details h1 {
        margin: 8px;
    }
}

.profile-user-name {
    grid-area: profile-user-name;
    justify-self: left;
    font-weight: bold;
}

.profile-user-score {
    grid-area: profile-user-score;
    justify-self: right;
}

.profile-accuracy {
    grid-area: profile-accuracy;
    justify-self: right;
}

.bioForm textarea {
    display: block;
    outline: 0;
    border-width: 1px;
    border-color: black;
    background-color: white;
    margin: 0;
    padding: 0;
    height: 20vh;
    width: 100%;
    color: black;
    font-size: 18px;
    resize: none;
}

.bioForm button {
    margin: 0 auto;
    height: 38px;
    width: 180px;

    background-color: black;
    border-width: 0;
    color: white;
    border-radius: 5px;
}

.User-details {
    margin-left: 10px;
    margin-right: 10px;

}

.User-details p {
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: left;
}

.other-user-collection {
    display:grid;
    width: 90%;
    grid-template-columns: 25% 25% 25% 25%;
    grid-auto-flow: row dense;
    list-style:none;
}

.other-user-profile-button {
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 16px;
    margin-right: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
}

@media (min-width: 1354px) {
    .User-details {
        position: fixed;
        width: calc(var(--desktop-left-panel-width) - 20px);
    }
}

@media (max-width: 1354px) {
    .User-details {
        width: 92vw;
        margin-left: 4vw;
    }

    .other-user-container {
        display: none;
    }
}