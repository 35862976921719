:root {
  --app-background-color: rgb(255, 255, 255);
  --nav-bar-height: 64px;
  --h1-color: #000000;
  --p-color: #000000;

  --menu-bar-height: 60pt;

  --font-familly: 'Roboto', sans-serif;

  --desktop-left-panel-width: 33vw;

  --desktop-two-panel-breakpoint: 1354px;
}

.App {
  text-align: center;
  background-color: var(--app-background-color);
  min-height: 100vh;
}

#page-content-wrapper {
  margin-top: var(--menu-bar-height);
  width: 100%;
  height: calc(100vh - var(--menu-bar-height));
}

p {
  color: var(--p-color);
  font-family: var(--font-familly);
}

h1 {
  color: var(--h1-color);
  font-family: var(--font-familly);
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 66px;
  text-align: left;
}

h2 {
  color: var(--h1-color);
  font-family: var(--font-familly);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
}

input {
  display: block;
  border-width: 1px;
  border-color: black;
  border-radius: 5px;
  background-color: white;
  height: 48px;
  width: 322px;
  color: #3C3C3C;
  font-size: 20px;
  text-align: center;
  padding: 0;
}


input[type=submit] {
  border-width: 1px;
  border-color: black;
  border-radius: 5px;
  background-color: black;
  height: 48px;
  width: 322px;
  color: white;
  font-size: 20px;
  text-align: center;
  margin: 24px 0;
  padding: 0;
}

input[type=email] {
  margin: 0 auto;
  padding: 0;
}

input[type=password] {
  margin: 24px auto;
  padding: 0;
}

a:-webkit-any-link {
  text-decoration: none;
}

button {
  cursor: pointer;
}

#doorbell-button {
  right: unset;
  left: 5%
}

#doorbell-submit-button {
  border: 1px solid black;
  background: black;
}

#doorbell #doorbell-powered-by {
  display: none;
}