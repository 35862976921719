
.PredictionFeed h2 {
    color: black;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
}

.PredictionFeed ul {
    padding: 0;
}

.PredictionFeed h1 {
    color: black;
}

@media (min-width: 1354px) {
    .PredictionFeed {
        margin-left: calc(var(--desktop-left-panel-width) + 1px);
        margin-top: 100px;
    }
}