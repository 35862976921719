.suggestions,
.no-suggestions {
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

.suggestions li {
    list-style-type: none;
    min-height: 44px;
    font-size: 26px;
    padding-left: 8px;
    padding-right: 8px;
    border: 0;
    border-bottom: 1px solid #CCC;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.no-suggestions p {
    padding: 0;
    margin: 0;
}

.autocompleteContainer {
    max-width: 400pt;
    margin: 0 auto;
    border: 0;
}

.selectedSuggestion,
.unselectedSuggestion,
.suggestions,
.no-suggestions {
    width: 100%;
    /*max-width: 400pt;*/
    box-sizing:border-box;
    margin: 0 auto;
    border: 0;
}

.selectedSuggestion {
    background-color: #9F9F9F;
}

.autocompleteInput {
    width: 100%;
    outline: none;
    border-radius: 0;
    box-sizing:border-box;
    border-style: solid;
}

.sourceCountDisplay {
    color: #888888;
}
