.JotCard {
    width: 90%;
    max-width: 400pt;
    margin: 10px auto;
    position: relative;
}

.JotCard h2 {
    text-align: left;
}

.creatorName {
    align-self: flex-start;
}

.resultWrappers {
    align-self: flex-end !important;
    flex-basis: 80px;
    width: 80px;
}

.jotResult {
    width: 100%;

    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;

    text-align: center !important;

    padding: 0 8px;

    background: #E0E0E0;
    border-radius: 4px;
}

.headerWrapper {
    display: flex;
    justify-content: space-between;
    min-width: 90%;
    margin: 0 16px 0 0;
}

.JotCard img {
    margin: 0 auto;
    width:50%;
    height:50%;
    display: inline;
}

.MarkPrediction {
    align-self: flex-end;
    height: 48px;
    width: calc(50% - 16px);
    border-width: 1px;
    background-color: white;
    border-radius: 5px;
}

.votingBar {
    margin: auto;
    width: 100%;
}

.JotCreatorInteractionsTopLevel{
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
}

.jotVotingSection
{
    flex-grow: 4;
    flex-basis: 41.6%;
}

.markingJotSection
{
    flex-grow: 4;
    flex-basis: 41.6%;
}

.flexboxSpacer
{
    flex-grow: 1;
}

.showVotingButtons
{
    height: 48px;
    width: 100%;
    border: 1px solid;
    background-color: white;
    color: black;
    border-radius: 5px;
    padding: 0;
}

.showMarkingButtons
{
    height: 48px;
    width: 100%;
    border: 1px solid;
    color: black;
    background-color: white;
    border-radius: 5px;
}

.spacer
{
    width: 32px;
    display: inline-block;
}

.display-linebreak {
    white-space: pre-line;
    text-align: left;
    word-wrap: break-word;
}

.castedVotes {
    background-color: #e0e0e0;
    padding: 13px 36px;
}

.hideElement {
    display: none !important;
}

.showElement {
    display: block !important;
}

.markingFlexContainer {
    display: flex;
    height: 48px;
    width: 100%;
}

.backButton {
    flex-grow: 2;

    height: 48px;

    border: 1px solid;
    background-color: white;
    border-radius: 5px;

    color: #000000;
}

input[type=button] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}

.markingButton {
    flex-grow: 4;
    flex-basis: 33.5%;

    height: 48px;

    background-color: black;
    border-width: 0;
    color: white;
    border-radius: 5px;
}

.votingFlexContainer {
    display: flex;
    height: 48px;
    width: 100%;
}

.VotingButton
{
    flex-grow: 4;
    flex-basis: 33.5%;

    height: 48px;

    background-color: black;
    border-width: 0;
    color: white;
    border-radius: 5px;
}

.timeSection
{
    width: 280px;
    margin: 8px auto;

    display: grid;
    grid-template-columns: 48px 1fr;
    grid-template-rows: 1fr;
    column-gap: 8px;
    align-items: center;
}

.timeButtons
{
    display: grid;
    width: 48px;
    height: 104px;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    row-gap: 8px;
}

.actionDescription {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 8px 0px;
    text-align: left;
    margin: 0;
}

.VotingWrapper {
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.createdDate {
    text-align: left;
}

.MarkingWrapper {
    padding-bottom: 8px;
    margin-bottom: 8px;
}

@media (min-width: 1354px) {
    .Welcome {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;

        margin-top: 48px;
        padding-left: 160px;
        padding-right: 160px;
    }

    .VotingWrapper {
        display: block;
    }

    .MarkingWrapper {
        display: block;
    }

    .showVotingButtons{
        display: none;
        height: 48px;
    }
}
@media (max-width: 1354px) {
    .VotingWrapper {
        display: none;
    }

    .MarkingWrapper {
        display: none;
    }

    .showVotingButtons{
        display: block;
        height: 48px;
    }
}

