.detailsLeft > h1 {
    margin-top: 0;
}

.detailsRignt img {
    margin-top: 56px;
}

.UserSignupDetails {
    display: grid;
    grid-template-columns: 480px;
    grid-template-rows: 350px 1fr ;

    margin-top: 48px;
    padding-left: 160px;
    padding-right: 160px;
}

.textButtonWrapper {
    max-width: 420px;
}

.detailsForm {
    margin:0 auto;
    text-align:center;
}

@media (min-width: 1354px) {
    .UserSignupDetails {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }

    .detailsRignt img {
        margin-top: 70px;
    }
}

@media (max-width: 1354px) {
    .signupOuterWrapper {
        margin: 0 auto;
        max-width: 420px;
    }

    .UserSignupDetails {
        max-width: 420px;
        padding: 0;
    }
}

@media (max-width: 700px) {
    .signupOuterWrapper {
        margin: 0 auto;
        width: 420px;
    }

    .UserSignupDetails {
        max-width: 420px;
        padding-left: 8px;
        padding-right: 8px;
    }
}