.MenuBar {
    /*self layout*/
    position: fixed;
    height: var(--menu-bar-height);
    top: 0;
    width: 100vw;
    background: white;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.25);
    z-index: 9999;

    /*Child layout*/
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: [navigationSection]auto [spacer-left] 1fr  [user-score] 150px [log-out] 120px;
    justify-items: center;
    align-items: center;
    grid-template-areas:
    "navigationSection . user-score log-out";
}

.navigation-Section {
    grid-area: navigationSection;
}

.drop-down-navigation-buttons {
    display: none;
}

.broken-out-navigation-buttons {
    /*Child layout*/
    display: grid;
    grid-template-columns: [margin-spacer] 32px [page-button]auto [page-button-line-divider] 37px [page-button] auto  [page-button-line-divider] 37px [page-button] auto;
    justify-items: center;
    align-items: center;
    grid-template-areas:
    ". all-jots-page-button page-button-line-divider-1 profile-page-button page-button-line-divider-2 whats-new-page-button";
}

.popover-menu {
    /*display: none;*/
    position: fixed;
    height: auto;
    top: calc(var(--menu-bar-height));
    left: 0;
    width: 100vw;
    padding: 0;
    margin: 0;
    background: white;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.25);
    z-index: 9999;
}

.page-button {
    background-color: transparent;

    color: #000000;
    border: transparent;

    padding: 0;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.page-button:focus {outline:0;}

@media (max-width: 1354px) {
    .MenuBar {
        grid-template-columns: [navigationSection]64px [spacer-right] 1fr;
        grid-template-areas:
    "navigationSection .";
    }

    .popover-line-divider {
        height: 1px;
        width: calc(100vw - 32px);
        background: #000000;
        color: #000000;
        margin: 0 16px;
        padding: 0;
    }

    .drop-down-navigation-buttons {
        display: block;
    }

    .broken-out-navigation-buttons {
        display: none;
    }

    .broken-out-navigation-buttons * {
        display:none;
    }

    .MenuBar-nav {
        display: none;
    }

    .logout-button {
        display: none;
    }

    #userScore {
        display: none;
    }

    .page-button {
        display: block;
        margin: 16px 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
    }
}

.dropdown-nav {
    background-color: transparent;
    border-color: transparent;
}

.dropdown-nav:focus {outline:0;}

.selected-page-button {
    font-weight: 500;

    border-bottom: 2px solid #000000;
}

#all-jots-page-button {
    grid-area: all-jots-page-button;
}

#profile-page-button {
    grid-area: profile-page-button;
}

#whats-new-page-button {
    grid-area: whats-new-page-button;
}

#page-button-line-divider-1 {
    grid-area: page-button-line-divider-1;
}

#page-button-line-divider-2 {
    grid-area: page-button-line-divider-2;
}

.page-button-line-divider {
    grid-area: page-button-line-divider;
    height: 24px;
    width: 1px;
    background: #000000;
    color: #000000;
    margin: 0 16px;
    padding: 0;
}

.tjlabsTitle {
    position: absolute;
    color: var(--h1-color);
    font-family: var(--font-familly);
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    width: 90px;
    left: calc(50% - 45px);
    text-align: center;

    /* identical to box height */
    text-transform: uppercase;
}

.MenuBar-nav {
    grid-area: log-out;
    height: 100%;
    margin: 0;
    padding-inline-start: 0px;


    /*Child layout*/
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

#userScore {
    grid-area: user-score;

    max-width: calc(100vw - 104px - 108px);

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #000000;
}

.MenuBarItem {
    width: calc(var(--nav-bar-height) * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.logout-button {
    height: 40px;
    width: 70px;
    background-color: white;
    border-radius: 5px;
    border-color: black;
    border-width: 1px;
}