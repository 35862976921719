.prediction-centre-screen-divider {
    height: 1px;
    width: 96vw;
    background: #9F9F9F;
    margin-left: 2vw;
    margin-top: 8px;
}

.bottom-spacer {
    height: 20px;
}

#save {
    margin-top: 8px;
}

@media (min-width: 1354px) {
    .CreatePredictionPanel {
        position: fixed;
        margin-left: 10px;
        width: var(--desktop-left-panel-width);
    }

    .prediction-centre-screen-divider {
        position: fixed;
        height: calc( 100vh - (var(--menu-bar-height) + 20px));
        top: calc(var(--menu-bar-height) + 10px);
        width: 1px;
        background: #9F9F9F;
        margin-left: var(--desktop-left-panel-width);
    }
}
