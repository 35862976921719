.whatsNewTitle {
    font-size: 32px;
    text-align: center;
}

.whatsNewContainer {
    width: 90%;
    max-width: 400pt;
    margin: 0px auto;
    text-align: left;
}

.whatsNewContainer li {
    margin-bottom: 16px;
}

.SuggestionDropdown {
    margin: 0px auto;
}
