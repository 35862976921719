.Welcome {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr ;
}

.detailsLeft {
    margin: calc(48px) auto;
}

.detailsLeft h1 {
    margin-bottom: 24px;
    margin-right: 8px;
    margin-left: 8px;
}

.detailsLeft h2 {
    margin: 0px;
    margin-left: 8px;
    margin-right: 8px;
}

.detailsRignt img {
    margin: 0px 1vw;
    max-width: 90vw;
}

.welcome-button {
    display: block;
    border-width: 1px;
    border-color: black;
    border-radius: 5px;
    background-color: black;
    height: 48px;
    width: 322px;
    color: white;
    font-size: 20px;
    text-align: center;
    margin: 24px auto;
    padding: 0;
}

#login-fields {
    margin: 32px auto;
}

.logging-in-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1354px) {
    .welcome-wrapper {
        margin: 0 auto;
        max-width: 420px;
    }

    .detailsRignt {
        margin: 56px auto;
    }
}

@media (min-width: 1354px) {
    .Welcome {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;

        margin-top: 48px;
        padding-left: 160px;
        padding-right: 160px;
    }
}